<template>
  <div style="height: inherit" v-if="permission.main_task.access">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Search task"
              v-model="search.search_data"
            />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <!-- <div class="dropdown">
          <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item> Reset Sort </b-dropdown-item>
          </b-dropdown>
        </div> -->
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="150"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <div class="todo-task-list media-list">
          <div
            v-for="(main_task, index) in main_tasks.data"
            :key="index"
            v-if="main_tasks.data.length"
          >
            <li class="todo-item bg-light">
              <div class="row align-items-center">
                <div class="col-lg-4 d-flex">
                  <feather-icon
                    icon="AlignJustifyIcon"
                    class="mr-1 text-primary"
                    size="21"
                  />
                  <div class="font-weight-bolder">
                    {{ main_task.title }}
                  </div>
                </div>
                <div class="col-lg-3">
                  <small class="mr-1">
                    <span>{{ main_task.start_date | date_short }}</span>

                    <span class="text-danger" v-if="main_task.end_date">
                      -
                      {{ main_task.end_date | date_short }}
                    </span>
                  </small>
                </div>
                <div class="col-lg-2 text-right">
                  <b-badge
                    style="margin: 2px"
                    pill
                    :variant="
                      main_task.priority == 1
                        ? 'light-danger'
                        : main_task.priority == 2
                        ? 'light-primary'
                        : 'light-warning'
                    "
                  >
                    {{
                      main_task.priority == 1
                        ? "High"
                        : main_task.priority == 2
                        ? "Medium"
                        : "Low"
                    }}
                  </b-badge>
                  <b-badge
                    style="margin: 2px"
                    pill
                    :variant="
                      main_task.is_progress == 1
                        ? 'light-warning'
                        : main_task.is_progress == 2
                        ? 'light-primary'
                        : main_task.is_progress == 3
                        ? 'light-success'
                        : 'light-danger'
                    "
                  >
                    {{
                      main_task.is_progress == 1
                        ? "Not Started"
                        : main_task.is_progress == 2
                        ? "On Going"
                        : main_task.is_progress == 3
                        ? "Finished"
                        : "Cancel"
                    }}
                  </b-badge>
                </div>
                <div class="col-lg-3 d-flex justify-content-end">
                  <div class="todo-item-action justify-content-end">
                    <b-avatar
                      v-for="(department, index) in main_task.departments"
                      :key="index"
                      v-if="main_task.departments.length"
                      style="margin-left: 5px"
                      variant="secondary"
                      v-b-tooltip.hover
                      :title="department.name"
                      :text="getChar(department.name)"
                    />
                  </div>
                  <!-- <button
                    class="btn btn-sm btn-primary mx-1"
                    v-if="permission.main_task.edit"
                  >
                    <feather-icon icon="EditIcon" />
                  </button> -->
                  <button
                    v-if="permission.task.create"
                    class="btn btn-sm btn-success ml-1"
                    @click="
                      getAddTaskModel(main_task.id, main_task.departments)
                    "
                  >
                    <b> <feather-icon size="15" icon="PlusIcon" /></b>
                  </button>
                </div>
              </div>
            </li>
            <li
              class="todo-item"
              v-for="(task, index) in main_task.tasks"
              :key="index"
              v-if="main_task.tasks.length"
              @click="getTaskModel(task.id)"
            >
              <div class="row align-items-center">
                <div class="col-lg-4">
                  <div class="font-weight-bolder">
                    {{ task.title }}
                  </div>
                  <b-badge
                    style="margin: 2px"
                    :variant="
                      task.work_type == 1
                        ? 'primary'
                        : task.work_type == 2
                        ? 'success'
                        : 'secondary'
                    "
                  >
                    {{
                      task.work_type == 1
                        ? "Daily Work"
                        : task.work_type == 2
                        ? "Weekly Work"
                        : task.work_type == 3
                        ? "Monthly Work"
                        : "Quarterly Work"
                    }}
                  </b-badge>
                </div>
                <div class="col-lg-3">
                  <div v-if="task.is_progress != 3">
                    <small
                      class="text-muted"
                      v-if="!task.last_update && !task.next_update"
                    >
                      <span><b>Not Work Start</b></span>
                    </small>
                    <small v-if="task.last_update || task.next_update">
                      <span v-if="task.last_update"
                        ><b>Last Update Date : </b
                        >{{ task.last_update | date_short }}</span
                      >
                      <br />
                      <span
                        v-if="task.next_update"
                        :class="
                          task.work_status == 1
                            ? ''
                            : task.work_status == 2
                            ? 'text-warning'
                            : 'text-danger'
                        "
                      >
                        <b>Next Update Date :</b>
                        {{ task.next_update | date_short }}
                      </span>
                    </small>
                  </div>
                  <div v-else>
                    <small v-if="task.actual_end_date || task.last_update">
                      <span v-if="task.actual_end_date"
                        ><b>Complete Date : </b
                        >{{ task.actual_end_date | date_short }}</span
                      >
                      <br />
                      <span v-if="task.last_update"
                        ><b>Last Update Date : </b
                        >{{ task.last_update | date_short }}</span
                      >
                    </small>
                  </div>
                </div>
                <div class="col-lg-3 text-right">
                  <b-badge
                    v-if="task.last_progress != null"
                    style="margin: 2px"
                    pill
                    :variant="
                      task.last_progress == 1
                        ? 'success'
                        : task.last_progress == 2
                        ? 'warning'
                        : 'danger'
                    "
                  >
                    {{
                      task.last_progress == 1
                        ? "GOOD"
                        : task.last_progress == 2
                        ? "AVERAGE"
                        : "POOR"
                    }}
                  </b-badge>
                  <b-badge
                    v-if="task.duration > 0"
                    pill
                    style="margin: 2px"
                    :variant="'primary'"
                  >
                    {{ task.duration }} Hour
                  </b-badge>
                  <b-badge
                    v-if="task.qty > 0"
                    pill
                    style="margin: 2px"
                    :variant="'success'"
                  >
                    {{ task.qty }} Qty.
                  </b-badge>
                  <b-badge
                    v-if="
                      task.work_status != 1 &&
                      task.work_status != null &&
                      task.is_progress != 3
                    "
                    style="margin: 2px"
                    pill
                    :variant="
                      task.work_status == 1
                        ? ''
                        : task.work_status == 2
                        ? 'warning'
                        : 'danger'
                    "
                  >
                    {{
                      task.work_status == 1
                        ? ""
                        : task.work_status == 2
                        ? "Last Date"
                        : "Date Expired"
                    }}
                  </b-badge>
                  <b-badge
                    style="margin: 2px"
                    pill
                    :variant="
                      task.priority == 1
                        ? 'light-danger'
                        : task.priority == 2
                        ? 'light-primary'
                        : 'light-warning'
                    "
                  >
                    {{
                      task.priority == 1
                        ? "High"
                        : task.priority == 2
                        ? "Medium"
                        : "Low"
                    }}
                  </b-badge>
                  <b-badge
                    style="margin: 2px"
                    pill
                    :variant="
                      task.is_progress == 1
                        ? 'light-warning'
                        : task.is_progress == 2
                        ? 'light-primary'
                        : task.is_progress == 3
                        ? 'light-success'
                        : 'light-danger'
                    "
                  >
                    {{
                      task.is_progress == 1
                        ? "Not Started"
                        : task.is_progress == 2
                        ? "On Going"
                        : task.is_progress == 3
                        ? "Finished"
                        : "Cancel"
                    }}
                  </b-badge>
                </div>
                <div class="col-lg-2">
                  <div class="todo-item-action justify-content-end">
                    <b-avatar
                      v-for="(employee, employee_index) in task.employees"
                      :key="employee_index"
                      v-if="task.employees.length"
                      style="margin-left: 5px"
                      variant="secondary"
                      v-b-tooltip.hover
                      :title="employee.name"
                      :text="getChar(employee.name)"
                    />
                  </div>
                </div>
              </div>
            </li>
          </div>
        </div>
        <div class="no-results" :class="{ show: !main_tasks.data.length }">
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <portal to="search_data">
      <div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <Select
                v-model="search.work_status"
                filterable
                :clearable="true"
                @on-change="SearchData"
                name="work_type"
                id="work_type"
                placeholder="Select Task Status"
              >
                <Option :value="1">My Task</Option>
                <Option :value="2">All Task</Option>
              </Select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <Select
                v-model="search.department_id"
                filterable
                multiple
                :clearable="true"
                @on-change="searchGetEmployee"
                name="department_id"
                id="department_id"
                placeholder="Select Department"
              >
                <Option
                  :value="department.id"
                  v-for="(department, index) in departments"
                  :key="index"
                  v-if="departments.length"
                  >{{ department.name }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <Select
                v-model="search.employee_id"
                :clearable="true"
                @on-change="SearchData"
                filterable
                name="employee_id"
                id="employee_id"
                placeholder="Select Employee"
              >
                <Option
                  :value="employee.id"
                  v-for="(employee, index) in employees"
                  :key="index"
                  v-if="employees.length"
                  >{{ employee.name }}</Option
                >
              </Select>
            </div>
          </div>
        </div>
      </div>
    </portal>

    <portal to="pagination">
      <div class="m-1">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ main_tasks.from ? main_tasks.from : 0 }} to
              {{ main_tasks.to ? main_tasks.to : 0 }} of
              {{ main_tasks.total ? main_tasks.total : 0 }}
              entries</span
            >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <pagination
              :data="main_tasks"
              @pagination-change-page="getResults"
              align="right"
              :show-disabled="true"
              :limit="5"
            ></pagination>
          </b-col>
        </b-row>
      </div>
    </portal>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <div
        class="sidebar-left"
        :class="{ show: mqShallShowLeftSidebar }"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      >
        <div class="sidebar">
          <div class="sidebar-content todo-sidebar">
            <div class="todo-app-menu">
              <div class="add-task" v-if="permission.main_task.create">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click="getAddMainTaskModel"
                >
                  Add Main Task
                </b-button>
              </div>
              <vue-perfect-scrollbar
                :settings="60"
                class="sidebar-menu-list scroll-area"
              >
                <!-- Filters -->
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.is_progress == 2"
                    button
                    @click="getIsProgress(2)"
                  >
                    <feather-icon
                      icon="RotateCcwIcon"
                      size="18"
                      class="mr-75"
                    />
                    <span class="align-text-bottom line-height-1"
                      >On Going</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.is_progress == 3"
                    button
                    @click="getIsProgress(3)"
                  >
                    <feather-icon icon="CheckIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1"
                      >Completed</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.is_progress == 4"
                    button
                    @click="getIsProgress(4)"
                  >
                    <feather-icon icon="TrashIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1">Deleted</span>
                  </b-list-group-item>
                </b-list-group>

                <!-- Tags -->
                <div class="mt-3 px-2 d-flex justify-content-between">
                  <h6 class="section-label mb-1">Work Status</h6>
                </div>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == null"
                    button
                    @click="getWorkType(null)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-secondary`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >All Task</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == 5"
                    button
                    @click="getWorkType(5)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-success`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >Today Task</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == 1"
                    button
                    @click="getWorkType(1)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-info`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >Daily Task</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == 2"
                    button
                    @click="getWorkType(2)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-warning`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >Weekly Task</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == 4"
                    button
                    @click="getWorkType(4)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-danger`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >Quarterly Work</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == 3"
                    button
                    @click="getWorkType(3)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-danger`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >Monthly Task</span
                    >
                  </b-list-group-item>
                </b-list-group>

                <!-- Tags -->
                <div class="mt-3 px-2 d-flex justify-content-between">
                  <h6 class="section-label mb-1">Priority</h6>
                </div>

                <b-list-group class="list-group-labels">
                  <b-list-group-item
                    :active="search.priority == null"
                    button
                    @click="getPriority(null)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-info`"
                    />
                    <span>All</span>
                  </b-list-group-item>
                  <b-list-group-item
                    :active="search.priority == 1"
                    button
                    @click="getPriority(1)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-danger`"
                    />
                    <span>High</span>
                  </b-list-group-item>
                  <b-list-group-item
                    :active="search.priority == 2"
                    button
                    @click="getPriority(2)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-primary`"
                    />
                    <span>Medium</span>
                  </b-list-group-item>
                  <b-list-group-item
                    :active="search.priority == 3"
                    button
                    @click="getPriority(3)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-warning`"
                    />
                    <span>Low</span>
                  </b-list-group-item>
                </b-list-group>
              </vue-perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </portal>

    <Drawer
      title="Task Manage"
      v-model="task_model"
      width="425"
      :mask-closable="false"
      v-if="permission.task.access"
    >
      <div class="card-app-design">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <b-badge variant="light-primary">
              {{ task.created_at | date_time_format_short }}
            </b-badge>
          </div>
          <button
            class="btn btn-outline-primary btn-sm"
            @click="MarkCompleteAlert(task.id)"
            v-if="task.is_progress == 2"
          >
            Mark Complete
          </button>
        </div>
        <b-card-title class="mt-1 mb-75">
          {{ task.title }}
          <div class="font-small-2 text-muted">
            <span v-if="task.main_task" class="text-primary">
              <b>{{ task.main_task.title }}</b>
            </span>
          </div>
        </b-card-title>
        <b-card-text class="font-small-2 mb-2">
          {{ task.description }}
        </b-card-text>

        <div class="design-group">
          <h6 class="section-label">Members</h6>
          <b-avatar
            v-for="(employee, employee_index) in task.employees"
            :key="employee_index"
            v-if="task.employees.length"
            class="mr-1"
            variant="secondary"
            v-b-tooltip.hover
            :title="employee.name"
            :text="getChar(employee.name)"
          />
        </div>

        <div class="design-group" v-if="task.duration > 0 || task.qty > 0">
          <h6 class="section-label">Hour & Quantity</h6>
          <b-badge
            v-if="task.duration > 0"
            pill
            style="margin: 2px"
            :variant="'primary'"
          >
            {{ task.duration }} Hour
          </b-badge>
          <b-badge
            v-if="task.qty > 0"
            pill
            style="margin: 2px"
            :variant="'success'"
          >
            {{ task.qty }} Qty.
          </b-badge>
        </div>

        <div class="design-group">
          <h6 class="section-label">Status</h6>
          <b-badge
            style="margin-right: 5px"
            :variant="
              task.priority == 1
                ? 'danger'
                : task.priority == 2
                ? 'primary'
                : 'warning'
            "
          >
            {{
              task.priority == 1
                ? "High Priority"
                : task.priority == 2
                ? "Medium Priority"
                : "Low Priority"
            }}
          </b-badge>
          <b-badge
            style="margin-right: 5px"
            :variant="
              task.is_progress == 1
                ? 'warning'
                : task.is_progress == 2
                ? 'primary'
                : task.is_progress == 3
                ? 'success'
                : 'danger'
            "
          >
            {{
              task.is_progress == 1
                ? "Not Started"
                : task.is_progress == 2
                ? "On Going"
                : task.is_progress == 3
                ? "Finished"
                : "Cancel"
            }}
          </b-badge>
          <b-badge
            style="margin-right: 5px"
            :variant="
              task.work_type == 1
                ? 'warning'
                : task.work_type == 2
                ? 'primary'
                : task.work_type == 3
                ? 'success'
                : 'info'
            "
          >
            {{
              task.work_type == 1
                ? "Daily Work"
                : task.work_type == 2
                ? "Weekly Work"
                : task.work_type == 3
                ? "Monthly Work"
                : task.work_type == 4
                ? "Quarterly Work"
                : "Assign Task"
            }}
          </b-badge>
        </div>

        <div class="design-planning-wrapper">
          <div class="design-planning" v-if="task.last_update">
            <p class="card-text mb-25">Last Update Date</p>
            <h6 class="mb-0">
              {{ task.start_date | date_time_format_short }}
            </h6>
          </div>
          <div class="design-planning" v-if="task.next_update">
            <p class="card-text mb-25">Next Update Date</p>
            <h6 class="mb-0">
              {{ task.next_update | date_time_format_short }}
            </h6>
          </div>
        </div>
        <div
          class="mb-2"
          v-if="task.is_progress != 3 && permission.task.manage"
        >
          <div class="row">
            <div class="col-md-6">
              <b-form-group label="Start Time" label-for="start_date">
                <b-form-timepicker
                  v-model="done_task.start_date"
                  locale="en"
                  placeholder="Start Time"
                />
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="End Time" label-for="end_date">
                <b-form-timepicker
                  v-model="done_task.end_date"
                  locale="en"
                  placeholder="End Time"
                />
              </b-form-group>
            </div>
          </div>
          <div class="form-group text-center">
            <RadioGroup
              v-model="done_task.last_progress"
              type="button"
              class="w-100 d-block"
            >
              <Radio :label="1" style="width: 33.33%">GOOD</Radio>
              <Radio :label="2" style="width: 33.33%">AVERAGE</Radio>
              <Radio :label="3" style="width: 33.33%">POOR</Radio>
            </RadioGroup>
          </div>
          <b-form-group label="Remark" label-for="remark">
            <Input
              v-model="done_task.remark"
              type="textarea"
              :rows="2"
              placeholder="Enter Your Remark"
            />
          </b-form-group>
          <div class="w-100 text-right">
            <button class="btn btn-primary" @click="UpdateWork()">
              Update
            </button>
          </div>
        </div>
        <app-timeline>
          <app-timeline-item
            :variant="activity.is_timely == true ? 'success' : 'danger'"
            v-for="(activity, index) in activities"
            :key="index"
            v-if="activities.length"
          >
            <div
              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
            >
              <h6>
                {{ activity.start_date | time_format_short }} -
                {{ activity.end_date | time_format_short }}
                ({{ activity.end_date | date_short }})
              </h6>
              <small class="timeline-item-time text-nowrap text-muted ml-1">{{
                getHour(activity.done_duration)
              }}</small>
            </div>
            <b-badge
              :variant="
                activity.last_progress == 1
                  ? 'success'
                  : activity.last_progress == 2
                  ? 'warning'
                  : 'danger'
              "
            >
              {{
                activity.last_progress == 1
                  ? "GOOD"
                  : activity.last_progress == 2
                  ? "AVERAGE"
                  : "POOR"
              }}
            </b-badge>
            <p style="margin-top: 10px">{{ activity.remark }}</p>
            <b-avatar-group size="35px">
              <b-avatar
                v-for="(employee, employee_index) in activity.work_employees"
                :key="employee_index"
                v-if="activity.work_employees.length && employee.employee"
                variant="secondary"
                v-b-tooltip.hover
                :title="employee.employee.name"
                :text="getChar(employee.employee.name)"
                class="pull-up"
              />
            </b-avatar-group>
          </app-timeline-item>
        </app-timeline>
      </div>
    </Drawer>

    <Modal
      :title="(form_task.id == '' ? 'Create' : 'Update') + ' Task'"
      v-model="create_task_model"
      :mask-closable="false"
      :footer-hide="true"
      :closable="false"
      width="700"
    >
      <validation-observer ref="taskValidation">
        <b-form
          class="auth-login-form"
          @submit.prevent
          enctype="multipart/form-data"
        >
          <div class="row mb-2">
            <div class="col-md-6">
              <b-form-group label="Task Title" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="Task Title"
                  rules="required"
                >
                  <Input
                    name="title"
                    type="text"
                    v-model="form_task.title"
                    id="title"
                    placeholder="Enter Task Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Select Work Type" label-for="work_type">
                <validation-provider
                  #default="{ errors }"
                  name="work_type"
                  rules="required"
                >
                  <Select
                    v-model="form_task.work_type"
                    filterable
                    name="work_type"
                    id="work_type"
                    placeholder="Select Work Type"
                  >
                    <Option :value="1">Daily Work</Option>
                    <Option :value="2">Weekly Work</Option>
                    <Option :value="4">Quarterly Work</Option>
                    <Option :value="3">Monthly Work</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Task Quantity" label-for="qty">
                <validation-provider
                  #default="{ errors }"
                  name="Task Quantity"
                  :rules="form_task.type == 6 ? 'required' : ''"
                >
                  <InputNumber
                    name="qty"
                    v-model="form_task.qty"
                    id="qty"
                    placeholder="Enter Task Quantity"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Task Duration (Hour)" label-for="duration">
                <validation-provider
                  #default="{ errors }"
                  name="Task Duration"
                  :rules="form_task.type == 5 ? 'required' : ''"
                >
                  <InputNumber
                    name="duration"
                    v-model="form_task.duration"
                    id="duration"
                    placeholder="Enter Task Duration"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Start Date" label-for="start_date">
                <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form_task.start_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select Start Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="End Date" label-for="end_date">
                <validation-provider #default="{ errors }" name="End Date">
                  <flat-pickr
                    v-model="form_task.end_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select End Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Select Priority" label-for="priority">
                <validation-provider
                  #default="{ errors }"
                  name="Priority"
                  rules="required"
                >
                  <Select
                    v-model="form_task.priority"
                    filterable
                    name="priority"
                    id="priority"
                    placeholder="Select Priority"
                  >
                    <Option :value="1">High</Option>
                    <Option :value="2">Medium</Option>
                    <Option :value="3">Low</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Select Employee" label-for="employee_id">
                <validation-provider
                  #default="{ errors }"
                  name="Employee"
                  rules="required"
                >
                  <Select
                    v-model="form_task.employee_id"
                    filterable
                    name="employee_id"
                    id="employee_id"
                    placeholder="Select Employee"
                  >
                    <Option
                      :value="employee.id"
                      v-for="(employee, index) in employees"
                      :key="index"
                      v-if="employees.length"
                      >{{ employee.name }}</Option
                    >
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                label="Description"
                label-for="description"
                rules="required"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <Input
                    v-model="form_task.description"
                    type="textarea"
                    :rows="2"
                    placeholder="Enter Your Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="text-right">
            <b-button
              type="submit"
              class="mx-1"
              variant="outline-primary"
              @click="CloseTask"
            >
              Close
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              @click="validationTaskForm"
            >
              {{ form_task.id == "" ? "Create" : "Update" }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </Modal>

    <Modal
      :title="(form.id == '' ? 'Create' : 'Update') + ' Main Task'"
      v-model="create_main_task_model"
      :mask-closable="false"
      :footer-hide="true"
      :closable="false"
      width="700"
    >
      <validation-observer ref="mainTaskValidation">
        <b-form
          class="auth-login-form"
          @submit.prevent
          enctype="multipart/form-data"
        >
          <div class="row mb-2">
            <div class="col-md-12">
              <b-form-group label="Task Title" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="Task Title"
                  rules="required"
                >
                  <Input
                    name="title"
                    type="text"
                    v-model="form.title"
                    id="title"
                    placeholder="Enter Task Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Start Date" label-for="start_date">
                <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form.start_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select Start Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="End Date" label-for="end_date">
                <validation-provider #default="{ errors }" name="End Date">
                  <flat-pickr
                    v-model="form.end_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select End Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group label="Select Priority" label-for="priority">
                <validation-provider
                  #default="{ errors }"
                  name="Priority"
                  rules="required"
                >
                  <Select
                    v-model="form.priority"
                    filterable
                    name="priority"
                    id="priority"
                    placeholder="Select Priority"
                  >
                    <Option :value="1">High</Option>
                    <Option :value="2">Medium</Option>
                    <Option :value="3">Low</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group label="Select Department" label-for="department_id">
                <validation-provider
                  #default="{ errors }"
                  name="Department"
                  rules="required"
                >
                  <Select
                    v-model="form.department_id"
                    filterable
                    name="department_id"
                    id="department_id"
                    placeholder="Select Department"
                  >
                    <Option
                      :value="department.id"
                      v-for="(department, index) in departments"
                      :key="index"
                      v-if="departments.length"
                      >{{ department.name }}</Option
                    >
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                label="Description"
                label-for="description"
                rules="required"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <Input
                    v-model="form.description"
                    type="textarea"
                    :rows="2"
                    placeholder="Enter Your Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="text-right">
            <b-button
              type="submit"
              class="mx-1"
              variant="outline-primary"
              @click="CloseMainTask"
            >
              Close
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              @click="validationMainTaskForm"
            >
              {{ form_task.id == "" ? "Create" : "Update" }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </Modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BRow,
  BCol,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormTimepicker,
  BForm,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import VueSlider from "vue-slider-component";
import _ from "lodash";
import flatPickr from "vue-flatpickr-component";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    BAvatarGroup,
    BAvatar,
    VuePerfectScrollbar,
    VBTooltip,
    flatPickr,
    BButton,
    BListGroup,
    BListGroupItem,
    VueSlider,
    BImg,
    BCardBody,
    BCardHeader,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BMediaBody,
    BMediaAside,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormTimepicker,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      form_task: {
        id: "",
        main_task_id: null,
        default_task_id: null,
        project_id: null,
        title: null,
        start_date: null,
        end_date: null,
        work_type: null,
        priority: null,
        is_progress: 2,
        assign_status: 2,
        qty: null,
        duration: null,
        type: 1,
        employee_id: null,
        description: null,
      },
      form: {
        id: "",
        title: null,
        start_date: null,
        end_date: null,
        priority: null,
        is_progress: 2,
        assign_status: 2,
        department_id: null,
        description: null,
      },
      create_task_model: false,
      create_main_task_model: false,
      search: {
        search_data: "",
        department_id: [],
        employee_id: null,
        is_progress: 2,
        priority: null,
        work_type: null,
        work_status: 1,
        paginate: 2,
        page: 1,
      },
      done_task: {
        task_id: null,
        last_progress: null,
        start_date: null,
        end_date: null,
        remark: null,
      },
      main_tasks: {},
      task: {},
      activities: {},
      task_model: false,
      mqShallShowLeftSidebar: false,
    };
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getDepartment");
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const main_tasks = await this.callApi(
        "post",
        "/project/main/tasks/get/check/list?page=" + this.search.page,
        this.search
      );
      if (main_tasks.status == 200) {
        this.main_tasks = main_tasks.data.main_tasks;
      }
    },
    // Mark Complete
    MarkCompleteAlert(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to complete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, complete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.markComplete(id);
        }
      });
    },
    async markComplete(id) {
      const task = await this.callApi(
        "post",
        "/project/tasks/check/list/mark/complete/" + id,
        this.search
      );
      if (task.status == 200) {
        this.getTaskModel(task.data.task.id);
        this.getResults();
      }
    },
    // Add Sub Task
    getAddTaskModel(id, departments) {
      this.form_task.main_task_id = id;
      let department_id = [];
      departments.forEach((data) => {
        department_id.push(data.department_id);
      });
      this.$store.dispatch("getEmployee", department_id);
      this.create_task_model = true;
    },
    addTask() {
      axios
        .post("/project/tasks/store", this.form_task)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
          this.CloseTask();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.default_task_id)
              this.e(e.response.data.errors.default_task_id[0]);
            if (e.response.data.errors.main_task_id)
              this.e(e.response.data.errors.main_task_id[0]);
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
            if (e.response.data.errors.priority)
              this.e(e.response.data.errors.priority[0]);
            if (e.response.data.errors.is_progress)
              this.e(e.response.data.errors.is_progress[0]);
            if (e.response.data.errors.qty)
              this.e(e.response.data.errors.qty[0]);
            if (e.response.data.errors.duration)
              this.e(e.response.data.errors.duration[0]);
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
            if (e.response.data.errors.employee_id)
              this.e(e.response.data.errors.employee_id[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
          }
        });
    },

    CloseTask() {
      this.form_task.id = "";
      this.form_task.main_task_id = null;
      this.form_task.title = null;
      this.form_task.start_date = null;
      this.form_task.end_date = null;
      this.form_task.priority = null;
      this.form_task.work_type = null;
      this.form_task.employee_id = null;
      this.form_task.description = null;
      this.form_task.qty = null;
      this.form_task.duration = null;
      this.create_task_model = false;
    },

    validationTaskForm() {
      this.$refs.taskValidation.validate().then((success) => {
        if (success) {
          this.addTask();
        }
      });
    },

    // Done Task
    UpdateWork() {
      if (
        this.done_task.start_date == null ||
        this.done_task.start_date == ""
      ) {
        return this.i("Start Time Field Is Required :(");
      }
      if (this.done_task.end_date == null || this.done_task.end_date == "") {
        return this.i("End Time Field Is Required :(");
      }

      if (this.done_task.remark == null || this.done_task.remark == "") {
        return this.i("Remark Field Is Required :(");
      }
      if (
        this.done_task.last_progress == null ||
        this.done_task.last_progress == ""
      ) {
        return this.i("Last Progress Field Is Required :(");
      }
      this.done_task.task_id = this.task.id;
      axios
        .post("/project/tasks/check/list/done", this.done_task)
        .then((res) => {
          this.s(res.data.message);
          this.done_task.percentage = res.data.task.done_percentage;
          this.getTaskModel(res.data.task.id);
          this.getResults();
          this.CloseWork();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.task_id)
              this.e(e.response.data.errors.task_id[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
            if (e.response.data.errors.remark)
              this.e(e.response.data.errors.remark[0]);
            if (e.response.data.errors.last_progress)
              this.e(e.response.data.errors.last_progress[0]);
          }
        });
    },

    async getTaskModel(id) {
      const task = await this.callApi(
        "post",
        "/project/get/tasks/check/list/" + id,
        this.search
      );
      if (task.status == 200) {
        this.task = task.data.task;
        this.activities = task.data.activities;
        this.task_model = true;
      }
    },
    CloseWork() {
      this.done_task.task_id = null;
      this.done_task.start_date = null;
      this.done_task.end_date = null;
      this.done_task.last_progress = null;
      this.done_task.remark = null;
    },

    // Main Task
    getAddMainTaskModel() {
      this.create_main_task_model = true;
    },

    addMainTask() {
      axios
        .post("/project/main/tasks/store/check/list", this.form)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
          this.CloseMainTask();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
            if (e.response.data.errors.priority)
              this.e(e.response.data.errors.priority[0]);
            if (e.response.data.errors.is_progress)
              this.e(e.response.data.errors.is_progress[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
            if (e.response.data.errors.department_id)
              this.e(e.response.data.errors.department_id[0]);
          }
        });
    },

    validationMainTaskForm() {
      this.$refs.mainTaskValidation.validate().then((success) => {
        if (success) {
          this.addMainTask();
        }
      });
    },

    CloseMainTask() {
      this.form.id = "";
      this.form.title = null;
      this.form.start_date = null;
      this.form.end_date = null;
      this.form.priority = null;
      this.form.is_progress = 2;
      this.form.description = null;
      this.form.department_id = null;
      this.create_main_task_model = false;
    },

    // Task Status & Filter
    searchGetEmployee() {
      this.$store.dispatch("getEmployee", this.search.department_id);
      this.search.page = 1;
      this.getResults();
    },

    getHour(hour) {
      if (hour > 0) {
        let day = 0;
        let ret_hour = 0;
        let ret_minute = 0;
        if (hour >= 8) {
          let hour_data = hour % 8;
          day = (hour - hour_data) / 8;

          if (hour_data >= 1) {
            let minutes = hour_data % 1;
            ret_hour = hour_data - minutes;
            ret_minute = Math.round(minutes * 60);
          } else {
            ret_minute = Math.round(hour_data * 60);
          }
        } else {
          if (hour >= 1) {
            let minutes = hour % 1;
            ret_hour = hour - minutes;
            ret_minute = Math.round(minutes * 60);
          } else {
            ret_minute = Math.round(hour * 60);
          }
        }
        return (
          (day > 0 ? day + " Day " : "") +
          ((day > 0 && ret_minute > 0) || ret_hour > 0
            ? ret_hour + " Hour "
            : "") +
          (ret_minute > 0 ? ret_minute + " Minutes" : "")
        );
      } else {
        return 0;
      }
    },
    getChar(text) {
      return text
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase()
        .substring(0, 3);
    },
    getIsProgress(status) {
      this.search.is_progress = status;
      this.search.page = 1;
      this.getResults();
    },
    getPriority(priority) {
      this.search.priority = priority;
      this.search.page = 1;
      this.getResults();
    },
    getWorkType(type) {
      this.search.work_type = type;
      this.search.page = 1;
      this.getResults();
    },
    getDepartmentChar(text) {
      return text
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase()
        .substring(0, 3);
    },
    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
  },
  computed: {
    departments() {
      return this.$store.getters.getDepartment;
    },
    employees() {
      return this.$store.getters.getEmployee;
    },
    permission() {
      return this.$store.getters.getPermissions;
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

  .cursor_pointer {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "~@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
